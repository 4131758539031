.notif__container {
  padding-top: 120px;
}

.navbar {
  border-bottom: 1px solid #eee;
  background-color: #fff;
}

.navbar-brand {
  color: #1da1f2;
  font-weight: 700;
}

.sub-header {
  top: 54px;
  background-color: #1da1f2;
}

.sub-header .navbar-nav .nav-link.active {
  color: white;
  font-weight: 700;
  text-decoration: underline;
}

.sub-header .navbar-nav .nav-link {
  color: #fff;
}

.ant-calendar-picker {
  width: 100% !important;
  display: block;
}

body {
  background-color: #f7f7f7 !important;
}

.BootstrapTable-hover {
  cursor: pointer;
}

.DashboardScene-card h4{
  text-align: center;
}

.DashboardScene-card .icon-container {
  font-size: 70px;
  text-align: center;
}

